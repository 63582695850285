import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Legend from "./../Legend/Legend";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  ToolboxComponent,
} from "echarts/components";

import { SVGRenderer } from "echarts/renderers";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useCurrentStat } from "../StatContext";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
  LegendComponent,
  ToolboxComponent,
]);

const transformAxisLabel = (label) => label.replace('_', '-');

const orderedValues = (values) => {
	if (typeof values === 'object') {
		const ordered = Object.entries(values)
			.sort((a,b) => a[0] > b[0] ? 1 : -1)
			.map(([key, value]) => ({ month: key, ...value }));
		return ordered;
	}
	
	return [];
}

const InfoModal = (props) => {
	const { currentStat } = useCurrentStat();

  const [params] = useSearchParams();
  const byUR = props?.modalContent?.by || params.get("byUR") || "utility";

  function modalTitle() {
    const title = { __html: props.modalContent.title };
    return <div dangerouslySetInnerHTML={title} />;
  }

  function modalContent() {
    const content = { __html: props.modalContent.content };
    return <div dangerouslySetInnerHTML={content} />;
  }

  const echartsOption = useMemo(() => ({
    title: {
      text: currentStat === "thd" ? "Percent of Homes with THD >8%" : "CPQI / Home / Month",
      left: "center",
    },
    tooltip: {
      trigger: "axis",
	  formatter: currentStat === "thd" ? null : null,
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      top: "12%",
      data: currentStat === "thd" ? [] : ["Surges x5", "Brownouts", "Power Outages", "PQ Index"],
    },
    toolbox: {
      feature: {
        //saveAsImage: {}
      },
    },
    grid: {
      top: "25%",
      right: "4%",
      bottom: "3%",
      left: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: props.modalContent.data
          ? Object.keys(props.modalContent.data).sort().map(transformAxisLabel)
          : [],
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: currentStat === "thd" ? 40 : 1.5
      },
    ],
    series: currentStat === "thd"
		? [
			{
				name: "THD >8%",
				type: "line",
				// color: "#1f44ae",
				// stack: "total",
				lineStyle: {
					color: "#1f44ae",
				},
				areaStyle: {
					color: "#6f87cd",
					opacity: 0
				},
				// emphasis: {
				// 	focus: "series",
				// },
				data: props.modalContent.data
				? orderedValues(props.modalContent.data)
					.map(({ data_by_utilities, data_by_sqmap }) => {
					let data;
					if (byUR == "utility") {
						data = Object.values(data_by_utilities).find(
						({ utitlity_name }) =>
							utitlity_name === props.modalContent.content
						);
						const value = data ? parseFloat(parseFloat(data["thd_8per"]).toFixed(1)) : 0;
						return value;
					} else {
						try {
							const [lat, lon] = props.modalContent.content;
							data = data_by_sqmap["thd_8per"][lat][lon];
						} catch (error) { }
						const value = data ? parseFloat(parseFloat(data).toFixed(1)) : 0;
						return value;
					}
					})
				: [],
			}
		]
		: [
			{
				name: "Surges x5",
				type: "line",
				stack: "Total",
				color: "#883013",
				lineStyle: {
				color: "#883013",
				},
				areaStyle: {
				color: "#c39789",
				},
				emphasis: {
				focus: "series",
				},
				data: props.modalContent.data
				? orderedValues(props.modalContent.data)
					.map(({ data_by_utilities, data_by_sqmap }) => {
					let data;
					if (byUR == "utility") {
						data = Object.values(data_by_utilities).find(
						({ utitlity_name }) =>
							utitlity_name === props.modalContent.content
						);
						return data ? data["swell_warning_events"] * 5 : 0;
					} else {
						try {
						const [lat, lon] = props.modalContent.content;
						data = data_by_sqmap["swell_warning_events"][lat][lon];
						} catch (error) { }
						return data ? data * 5 : 0;
					}
					})
				: [],
			},
			{
				name: "Brownouts",
				type: "line",
				stack: "Total",
				color: "#397329",
				lineStyle: {
				color: "#397329",
				},
				areaStyle: {
				color: "#9db994",
				},
				emphasis: {
				focus: "series",
				},
				data: props.modalContent.data
				? orderedValues(props.modalContent.data)
					.map(({ data_by_utilities, data_by_sqmap }) => {
					let data;
					if (byUR == "utility") {
						data = Object.values(data_by_utilities).find(
						({ utitlity_name }) =>
							utitlity_name === props.modalContent.content
						);
						return data ? data["sag_warning_events"] : 0;
					} else {
						try {
						const [lat, lon] = props.modalContent.content;
						data = data_by_sqmap["sag_warning_events"][lat][lon];
						} catch (error) { }
						return data ? data : 0;
					}
					})
				: [],
			},
			{
				name: "Power Outages",
				type: "line",
				stack: "Total",
				color: "#888686",
				lineStyle: {
				color: "#888686",
				},
				areaStyle: {
				color: "#c3c2c2",
				},
				emphasis: {
				focus: "series",
				},
				data: props.modalContent.data
				? orderedValues(props.modalContent.data)
					.map(({ data_by_utilities, data_by_sqmap }) => {
					let data;
					if (byUR == "utility") {
						data = Object.values(data_by_utilities).find(
						({ utitlity_name }) =>
							utitlity_name === props.modalContent.content
						);
						return data ? data["po_events"] : 0;
					} else {
						try {
						const [lat, lon] = props.modalContent.content;
						data = data_by_sqmap["po_events"][lat][lon];
						} catch (error) { }
						return data ? data : 0;
					}
					})
				: [],
			},
			{
				name: "PQ Index",
				type: "line",
				stack: "CPQI",
				color: "#1f44ae",
				lineStyle: {
				color: "#1f44ae",
				},
				areaStyle: {
				color: "#6f87cd",
				opacity: 0
				},
				emphasis: {
				focus: "series",
				},
				data: props.modalContent.data
				? orderedValues(props.modalContent.data)
					.map(({ data_by_utilities, data_by_sqmap }) => {
					let data;
					if (byUR == "utility") {
						data = Object.values(data_by_utilities).find(
						({ utitlity_name }) =>
							utitlity_name === props.modalContent.content
						);
						return data ? data["cpqi"] : 0;
					} else {
						try {
						const [lat, lon] = props.modalContent.content;
						data = data_by_sqmap["cpqi"][lat][lon];
						} catch (error) { }
						return data ? data : 0;
					}
					})
				: [],
			},
		],
  }), [props.modalContent, currentStat]);

  return (
    <Modal
      className={`ting-modal ${props.modalContent.type} stat-${currentStat}`}
      show={props.modalShow}
      onHide={props.setModalShow}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`modal-body-inner`}>
          {props.modalContent.type === "info" ? (
            <div className="row">
              <div className="col-8">{modalContent()}</div>
              <div className="col-4">
                <Legend />
              </div>
            </div>
          ) : (
            <div className={`chart-frame`}>
              <div className={`chart-label-title y-axis`}>{currentStat === "thd" ? "Percentage" : "CPQI / Month"}</div>
              <div className={`chart-label-title x-axis`}>Date</div>
              <ReactEChartsCore
                echarts={echarts}
                option={echartsOption}
                notMerge={true}
                lazyUpdate={true}
                opts={{ renderer: "svg" }}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.setModalShow}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
