import { Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "./../assets/images/logo_ting_by_whisker.svg";
import cpqiScreen from "./../assets/images/cpqi-screen.jpg";
import thdScreen from "./../assets/images/thd-screen.jpg";
import Footer from "./Footer/Footer";

const StatChoiceModal = (props) => {
	return (
		<Modal {...props} className="cpqi-thd-choice-modal" fullscreen>
			<Modal.Body>
				<header className="d-flex align-items-end">
					<div className="flex-fill">
						<h1><small>Measuring and Tracking</small><br />Grid Resiliency</h1>
						<p>with the most comprehensive sensor network to ever monitor the grid</p>
					</div>
					<div className="logo">
						<img alt={`Ting Logo`} className="me-4 mb-3" src={logo} width="100px" />
					</div>
				</header>
				<div className="divider">Explore Ting Sensor Network data sets</div>
				<div className="d-flex gap-4">
					<Card as={Link} className="flex-fill w-50" onClick={props.onHide} to="/cpqi">
						<Card.Body>
							<h2>Consumer Power Quality Index (CPQI)</h2>
							<p>Measures grid faults that cause damage and community power outages</p>
						</Card.Body>
						<div className="screen-image cpqi">
							<Card.Img variant="bottom" src={cpqiScreen} />
						</div>
					</Card>
					<Card as={Link} className="flex-fill w-50" onClick={props.onHide} to="/thd">
						<Card.Body>
							<h2>Total Harmonic Distortion (THD)</h2>
							<p>Measures the distortion in power delivered to homes, which reduces energy efficiency and damages appliances</p>
						</Card.Body>
						<div className="screen-image thd">
							<Card.Img variant="bottom" src={thdScreen} />
						</div>
					</Card>
				</div>
			</Modal.Body>
			<Footer />
		</Modal>
	);
};

export default StatChoiceModal;
