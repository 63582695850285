import { createContext, useCallback, useContext, useMemo, useState } from "react";
import StatChoiceModal from "./StatChoiceModal";
import { Helmet } from "react-helmet";

const CurrentStatContext = createContext(null);

export const StatProvider = ({ children, stat }) => {

	const [statModalOpen, setStatModalOpen] = useState(false);

	const toggleStatModal = useCallback(() => setStatModalOpen((open) => !open), []);

	const contextProps = useMemo(() => ({
		currentStat: stat,
		toggleStatModal
	}), [stat]);

	return (
		<CurrentStatContext.Provider value={contextProps}>
			{children}
			<StatChoiceModal centered onHide={toggleStatModal} show={!stat} />
		</CurrentStatContext.Provider>
	);
}

export const useCurrentStat = () => useContext(CurrentStatContext);
