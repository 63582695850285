import { useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Bars3Icon, BoltIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from 'react-router-dom';

function encodeStr(str){
    return encodeURIComponent(str)
      .replace('!', '%21')
      .replace('\'', '%27')
      .replace('(', '%28')
      .replace(')', '%29')
      .replace('*', '%2A')
      .replace('%20', '+')
      .replace(' ', '+');
}


const pageSummary = "This is a sample page summary";
const pageSummaryEncoded = encodeStr(pageSummary);

const ShareButtons = () => {
	
	const [searchParams] = useSearchParams();
	const pageTitleEncoded = useMemo(() => encodeStr(document.title), [document.title]);
	const shareUrls = useMemo(() => {
		const pageUrl = window.location.href;
		const pageUrlEncoded = encodeStr(pageUrl);

		return {
			facebook: "https://www.facebook.com/sharer/sharer.php?u=" + pageUrlEncoded + "&quote=" + pageTitleEncoded,
			twitter: "https://twitter.com/intent/tweet?url=" + pageUrlEncoded + "&text=" + pageTitleEncoded,
			linkedIn: "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrlEncoded + "&title=" + pageSummaryEncoded + "&source=" + pageTitleEncoded
		};
	}, [searchParams, document.title]);

	return (
		<>
			<ul className={`header-social-list`}>
				<li className={`social-share-label`}>
					<span>Share</span>
				</li>
				<li className={`social-share-link facebook`}>
					<a href={shareUrls.facebook} target={`_blank`} title={`Click to Share on Facebook`}>
						<span className={`sr-only`}>Share on Facebook</span>
					</a>
				</li>
				<li className={`social-share-link twitter`}>
					<a href={shareUrls.twitter} target={`_blank`} title={`Click to Share on Twitter`}>
						<span className={`sr-only`}>Share on Twitter</span>
					</a>
				</li>
				<li className={`social-share-link linkedin`}>
					<a href={shareUrls.linkedIn} target={`_blank`} title={`Click to Share on LinkedIn`}>
						<span className={`sr-only`}>Share on LinkedIn</span>
					</a>
				</li>
			</ul>

			<Dropdown className={`header-mobile-social-share`}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					<Bars3Icon />
					<span className={`sr-only`}>Share</span>
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item href={shareUrls.facebook} target={`_blank`}>Share on Facebook</Dropdown.Item>
					<Dropdown.Item href={shareUrls.twitter} target={`_blank`}>Share on Twitter</Dropdown.Item>
					<Dropdown.Item href={shareUrls.linkedin} target={`_blank`}>Share on LinkedIn</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

export default ShareButtons;
