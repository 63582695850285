import { lazy, useEffect, useMemo, useState, useRef } from "react";
import Axios from "axios";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import Header from "./components/Header/Header";
import HeaderDesign from "./components/HeaderDesign/HeaderDesign";
import Footer from "./components/Footer/Footer";
import DataMap from "./components/DataMap/DataMap";
import DataGrid from "./components/DataGrid/DataGrid";
import Newsletter from "./components/Newsletter/Newsletter";
import InfoBox from "./components/InfoBox/InfoBox";

import THDHeader from "./components/Header/THDHeader";

import LinearProgress from "@mui/material/LinearProgress";
import { getYearMonthFromDate, validateYearMonth } from "./helpers";
import { StatProvider } from "./components/StatContext";
import { statStartMonth, statStartYear } from "./constants";

const InfoModal = lazy(() => import("./components/InfoModal/InfoModal"));

const fetchData = (yearMonth, thd = false) => {
	return Axios(`${process.env.REACT_APP_ROOT_DOMAIN}/${yearMonth}.json`);
};

function App({
	stat
}) {
  const [params, setParams] = useSearchParams();

  const queryYearMonth = params.get("month");
  const [yearMonth, setYearMonth] = useState(validateYearMonth(queryYearMonth) ? queryYearMonth : getYearMonthFromDate());

  const [data, setData] = useState(null);
  const [fetchAll, setFetchAll] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [infoData, setInfoData] = useState(null);

  const [toggleModal, setToggleModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    type: "",
    title: "",
    content: "",
    data: "",
  });

  const mapRef = useRef(null);

  const handleModalToggle = () => {
    setToggleModal(!toggleModal);
  };

  const [UTILITY_DICS, setUtilityDics] = useState(null);
  useEffect(() => {
	setLoading(true);
	import("./assets/json/utility_dics.json").then((value) => {
		setLoading(false);
		setUtilityDics(value);
	});
  }, []);

  useEffect(() => {
	let _yearMonth = String(yearMonth);
	if (params.get("byMY") === "year") {
		_yearMonth = getYearMonthFromDate();
	}

    if (fetchAll) {
    //   let date = new Date(yearMonth.replace("_", "-") + "-15");
		let date = new Date();
      const promises = [];
      const object = { [yearMonth]: data[yearMonth] };
      while (date.getFullYear() != statStartYear[stat] || date.getMonth() != statStartMonth[stat]) {
        date.setMonth(date.getMonth() - 1);

        const ym = getYearMonthFromDate(date);
        if (!data[ym]) {
          promises.push(
            fetchData(ym, stat === "thd").then(({ data: fetchedData }) => {
              setData((data) => ({ ...data, [ym]: fetchedData }));
              object[ym] = fetchedData;
            })
          );
        } else {
          object[ym] = data[ym];
        }
      }

      setLoading(true);
      Promise.all(promises)
        .then(() => {
        //   setInfoData(object);
        })
        .catch((e) => {
          console.log("Error while loading data");
        })
        .finally(() => {
			setInfoData(object);
			setLoading(false);
			setFetchAll(false);
        });
    } else {
      if (!data || !data[_yearMonth]) {
        setLoading(true);
        fetchData(_yearMonth, stat === "thd")
          .then(({ data: fetchedData }) => {
            setData((data) => ({ ...data, [_yearMonth]: fetchedData }));
          })
          .catch(() => {
			console.log("No data for this month yet.");

			// Fallback by one month.

			// 10/31/24 New calculation - Konstantin
			// const monthAgo = new Date();
			// monthAgo.setMonth(new Date().getMonth() - 1);
			// monthAgo.setDate(0);
			// const newYearMonth = getYearMonthFromDate(monthAgo);

			// Old newYearMonth Calc
			const newYearMonth = getYearMonthFromDate(new Date(new Date().setMonth(new Date().getMonth() - 2)));
			setYearMonth(newYearMonth);
		  })
          .finally(() => setLoading(false));
      }
    }

	if (params.get("byMY") === "month" || params.get("byMy") === null) {
		const newParams = {
			month: yearMonth
		}
		if (params.get("byUR")) newParams.byUR = params.get("byUR");
		if (params.get("byMY")) newParams.byMY = params.get("byMY");
		if (params.get("category")) newParams.category = params.get("category");
		setParams(newParams);
	} else {
		const newParams = {
			year: getYearMonthFromDate()
		}
		setParams(newParams);
	}
  }, [yearMonth, fetchAll, stat, setParams]);

  const fetchDataForModal = () => {
    setFetchAll(true);
  };

  const gridData = useMemo(() => {
    if (!data || !data[yearMonth]) return [];

    const ids = Object.keys(data[yearMonth].data_by_utilities);
    let lastYearIndex = [];

    if (params.get("byMY") === "year")
      lastYearIndex = Object.values(data[yearMonth].last_year_utility_pqindex);

    return [
      {
        ...data[yearMonth].national_average,
        id: "national",
        utitlity_name: "NATIONAL AVERAGE",
      },
      ...Object.values(data[yearMonth].data_by_utilities).map((data, index) =>
        params.get("byMY") === "year"
          ? {
              ...lastYearIndex[index],
              utitlity_name: data.utitlity_name,
              id: ids[index],
            }
          : {
              ...data,
              id: ids[index],
            }
      ),
    ];
  }, [data, yearMonth, params.get("byMY")]);

  const [selectedUtility, setSelectedUtility] = useState(null);
  const handleLoadMap = (name) => {
    setSelectedUtility(name);
    mapRef.current._mapPane.scrollIntoView();
  };

  return (
	<StatProvider stat={stat}>
		<div className={`App stat-${stat || "cpqi"}`}>
		{isLoading && (
			<Box
			sx={{
				width: "100%",
				position: "fixed",
				zIndex: "10000",
				top: "0px",
			}}
			>
			<LinearProgress />
			</Box>
		)}
		<HeaderDesign />

		<div className={`app-frame-outer`}>
			<div className={`app-frame-inner`}>
			{stat === "thd"
				? <THDHeader setYearMonth={setYearMonth} />
				: <Header setYearMonth={setYearMonth} />}

			<DataMap
				yearMonth={yearMonth}
				setYearMonth={setYearMonth}
				setSelectedUtility={setSelectedUtility}
				data={gridData}
				dataBySqmap={
				data
					? params.get("byMY") === "month"
					? data[yearMonth]?.data_by_sqmap
					: data[yearMonth]?.last_year_pqindex
					: null
				}
				mapBins={data ? data[yearMonth]?.sqmap_bins : null}
				selectedUtility={selectedUtility}
				fetchDataForModal={fetchDataForModal}
				setModalContent={setModalContent}
				onModalToggle={handleModalToggle}
				mapRef={mapRef}
				UTILITY_DICS={UTILITY_DICS}
			/>
			<main>
				<div className={`main-outer`}>
					<div className={`main-inner`}>
						<DataGrid
							infoData={infoData}
							data={gridData}
							loadMap={handleLoadMap}
							fetchDataForModal={fetchDataForModal}
							onModalToggle={handleModalToggle}
							setModalContent={setModalContent}
						/>

						<Container fluid className={`padding-right-0 padding-left-0`}>
						<Row className={`margin-left-0 margin-right-0`}>
							<Col
							xs={6}
							md={7}
							className={`footer-callouts callout-left callout-newsletter padding-left-0`}
							>
							<Newsletter />
							</Col>
							<Col
							xs={6}
							md={5}
							className={`footer-callouts callout-right callout-info-box padding-right-0`}
							>
							<InfoBox />
							</Col>
						</Row>
						</Container>
					</div>
				</div>
			</main>
			</div>
		</div>
		<Footer />
		<InfoModal
			modalShow={toggleModal}
			setModalShow={handleModalToggle}
			modalContent={{ ...modalContent, data: infoData }}
		/>
		</div>
	</StatProvider>
  );
}

export default App;
