import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  GeoJSON, MapContainer, Polyline, TileLayer,
  ZoomControl
} from "react-leaflet";
import { useSearchParams } from "react-router-dom";
import { MAP_CFG, MONTHS, statStartMonth, statStartYear } from "../../constants";
import { getColorMap, getGradeScale } from "../../helpers";
import Legend from "./../Legend/Legend";
import DateDropdown from "../../assets/images/date_dropdown.svg";
import DateDropdownGradient from "../../assets/images/date-dropdown-gradient.svg";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { Typography } from "@mui/material";
import { useCurrentStat } from "../StatContext";

const DownIcon = () => {
  return <ChevronDownIcon width={20} height={20} color="#647590" />
}

const DataMap = ({
  yearMonth,
  setYearMonth,
  data,
  dataBySqmap,
  mapBins,
  selectedUtility,
  setSelectedUtility,
  fetchDataForModal,
  setModalContent,
  onModalToggle,
  mapRef,
  UTILITY_DICS,
}) => {
	const { currentStat } = useCurrentStat();

  const { lon_bins = {}, lat_bins = {} } = mapBins || {};
  const geoRef = useRef(null);

  const options = useMemo(() => {
	if (!currentStat) return [];
    let date = new Date();
    const array = [];
    while (!(date.getMonth() == statStartMonth[currentStat] && date.getFullYear() === statStartYear[currentStat])) {
      date.setMonth(date.getMonth() - 1);
      const value = `${MONTHS[date.getMonth()]} ${date.getFullYear()}`;
      array.push(
        <MenuItem
          key={value}
          value={`${date.getFullYear()}_${date.getMonth() >= 9
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)
            }`}
        >
          {value}
        </MenuItem>
      );
    }

    return array;
  }, [currentStat, yearMonth]);

  const [params, _] = useSearchParams();
  const pq_type = currentStat === "thd" ? "thd_8per" : params.get("category") || "cpqi";
  const byMY = params.get("byMY") || "month";
  const byUR = currentStat === "thd" ? "utility" : params.get("byUR") || "utility";

  const style = useCallback(
    (feature) => {
      const grades = getGradeScale(pq_type);
	  const featureProps = {
        fillColor: getColorMap(feature.properties.pq_per_tings_per_bin, grades, -1),
        weight: 1,
        opacity: 1,
        color: "#676767",
        dashArray: "1",
        fillOpacity: 0.8,
      };
      return featureProps;
    }
  );

  const highlightFeature = useCallback((e) => {
    const layer = e.target;
    layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }, []);

  const resetHighlight = useCallback((e) => {
    const bin_to_inspect = { target: { _leafleat_id: -1 } };
    if (e["target"]["_leaflet_id"] != bin_to_inspect["target"]["_leaflet_id"]) {
      geoRef.current.resetStyle(e.target);
    }
  }, []);

  const handleClickUtility = useCallback((e) => {
    if (byUR === "utility") {
      const utitlity_name = e.target.feature["properties"]["utility_name"];
      fetchDataForModal();
      const modalOutput = {
        type: `data`,
        title: `${utitlity_name}<br/><span>${currentStat === "thd" ? "" : "Power Outage + Brownouts + Surges"}</span>`,
        content: utitlity_name,
        data: ``,
      };
      setModalContent(modalOutput);

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'event',
          eventProps: {
            category: 'utilities',
            action: 'click_polygon',
            label: "Opened view utility details modal.",
            value: utitlity_name
          }
        });
      }

      onModalToggle();
    } else {
      // User is clicking on a square.
      const { lat_bin, lon_bin } = e.target.feature.properties;
      const lat_lon = [lat_bin, lon_bin];
      fetchDataForModal();
      const modalOutput = {
        type: `data`,
        title: `<span>Power Outage + Brownouts + Surges</span>`,
        content: lat_lon,
        data: ``,
      };
      setModalContent(modalOutput);

      onModalToggle();

    }
  },
    [byUR, currentStat, fetchDataForModal, onModalToggle, setModalContent]
  );

  const handleEachFeature = useCallback(
    (_, layer) => {
      layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: handleClickUtility,
      });
    },
    [highlightFeature, resetHighlight, handleClickUtility, currentStat]
  );

  const geoJSONData = useMemo(() => {
    if (data.length == 0 || !UTILITY_DICS) return;

    const vdata = { type: "FeatureCollection", features: [] };
    if (byUR === "utility") {
      data.forEach((data, index) => {
        if (index) {
          const feature = UTILITY_DICS.features.find(
            ({ properties: { NAME } }) => NAME === data.utitlity_name
          );
          feature["properties"]["pq_per_tings_per_bin"] = data[pq_type];
          feature["properties"]["utility_name"] = data.utitlity_name;
          vdata.features.push(feature);
        }
      });
    } else {
      const polygon_grid = [];
      const bin_location = [];
      const pq_per_tings_per_bin = [];
      const coord_indices = [[], []];
      // get the latiude/longitude indices of the bins that have data.
      for (const [keylat, londict] of Object.entries(dataBySqmap[pq_type])) {
        for (const [keylon, mydict] of Object.entries(londict)) {
          coord_indices[0].push(parseInt(keylat));
          coord_indices[1].push(parseInt(keylon));
        }
      }

      // for each latitude/longitude pair, create a polygon and append the yearly pq_data to a local array
      for (let ipoint = 0; ipoint < coord_indices[0].length; ipoint++) {
        let nlon = coord_indices[1][ipoint];
        let nlat = coord_indices[0][ipoint];
        let polyPoint0 = [lon_bins[nlon], lat_bins[nlat]];
        let polyPoint1 = [lon_bins[nlon], lat_bins[nlat + 1]];
        let polyPoint2 = [lon_bins[nlon + 1], lat_bins[nlat + 1]];
        let polyPoint3 = [lon_bins[nlon + 1], lat_bins[nlat]];

        polygon_grid.push([polyPoint0, polyPoint1, polyPoint2, polyPoint3]);
        let npq_events = dataBySqmap[pq_type][nlat][nlon];
        pq_per_tings_per_bin.push(npq_events);

        bin_location.push({
          ilat: nlat,
          ilon: nlon,
        });
      }

      // add each polygon as a geojson item
      for (let i = 0; i < polygon_grid.length; i++) {
        let feature = {
          type: "Feature",
          id: i,
          properties: {
            name: "name",
            pq_per_tings_per_bin: pq_per_tings_per_bin[i],
            lat_bin: bin_location[i]["ilat"],
            lon_bin: bin_location[i]["ilon"],
          },
          geometry: { type: "Polygon", coordinates: [polygon_grid[i]] },
        };
        vdata["features"].push(feature);
      }
    }

    return vdata;
  }, [pq_type, data, byMY, byUR, UTILITY_DICS, currentStat]);

  const polylines = useMemo(() => {
    if (!selectedUtility || !UTILITY_DICS) return [];

    let lat_values = 0,
      lon_values = 0;
    let poly_points = 0;
    const feature = UTILITY_DICS.features.find(
      ({ properties: { NAME } }) => NAME === selectedUtility
    );
    const polylines = [];

    if (feature["geometry"]["type"] == "Polygon") {
      for (let np = 0; np < feature["geometry"]["coordinates"].length; np++) {
        const u_polyline = [];
        let coord_arr = feature["geometry"]["coordinates"][np];

        for (let i = 0; i < coord_arr.length; i++) {
          u_polyline.push([coord_arr[i][1], coord_arr[i][0]]);
          lon_values += coord_arr[i][0];
          lat_values += coord_arr[i][1];
          poly_points++;
        }
        polylines.push(
          <Polyline
            key={`poly - ${np}`}
            pathOptions={{ color: "#ff00ff", width: 3 }}
            positions={u_polyline}
          />
        );
      }
    } else {
      let nmultipolygons = feature["geometry"]["coordinates"].length;
      for (let np = 0; np < nmultipolygons; np++) {
        let coord_arr = feature["geometry"]["coordinates"][np];
        for (let mp = 0; mp < coord_arr.length; mp++) {
          const mp_coord_arr = coord_arr[mp];
          let u_polyline = [];
          for (let i = 0; i < mp_coord_arr.length; i++) {
            u_polyline.push([mp_coord_arr[i][1], mp_coord_arr[i][0]]);
            lon_values += mp_coord_arr[i][0];
            lat_values += mp_coord_arr[i][1];
            poly_points++;
          }
          polylines.push(
            <Polyline
              key={`multi - ${np} - ${mp} - map`}
              pathOptions={{ color: "#ff00ff", width: 3 }}
              positions={u_polyline}
            />
          );
        }
      }
    }

    const zoom_start =
      -0.739 * Math.log(Math.abs(feature["properties"]["SHAPE_Area"])) + 9.1646;

    mapRef.current.setView(
      [lat_values / poly_points, lon_values / poly_points],
      zoom_start
    );

    return polylines;
  }, [selectedUtility, UTILITY_DICS, currentStat]);

  useEffect(() => {
    setSelectedUtility(null);
    if (mapRef.current)
      mapRef.current.setView(
        [MAP_CFG["map_latitude"], MAP_CFG["map_longitude"]],
        MAP_CFG["zoom_start"]
      );
  }, [byUR, currentStat]);

  const handleYearMonthChange = useCallback(({ target: { value } }) => setYearMonth(value), [setYearMonth]);
  const yearlyAverageLabel = useMemo(() => currentStat === "thd"
  	? `Feb 2024 - ${moment().format("MMM YYYY")}`
	: moment().subtract(1, "y").format("MMM YYYY") + " - " + moment().format("MMM YYYY"), [moment, currentStat]);

  return (
    <section className={`data-map`}>
      <div className={`data-map-outer`}>
        <div style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          zIndex: 1000,
          right: 0,
          left: 0,
		  height: 56
        }}>
          {byMY === "month"
		  	? (
				<Select
				value={yearMonth}
				onChange={handleYearMonthChange}
				style={{
					position: "absolute",
					fontSize: "18px",
					fontWeight: "400",
					color: "#647590"
				}}
				IconComponent={DownIcon}
				>
				{options}
				</Select>
			) : <Typography style={{ alignSelf: "center", fontSize: "18px", fontWeight: "400", color: currentStat === "thd" ? "white" : "#647590", zIndex: 1 }}>{yearlyAverageLabel}</Typography>
          }
        </div>

        <Legend />

        <div
          className={`data-map-inner`}
          style={{ height: "580px", width: "100%" }}
        >
          <MapContainer
            ref={mapRef}
            style={{ width: "100%", height: "580px", borderRadius: "16px" }}
            maxBounds={[
              [3, -195],
              [74, -40],
            ]}
            center={[MAP_CFG["map_latitude"], MAP_CFG["map_longitude"]]}
            zoom={MAP_CFG["zoom_start"]}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            minZoom={4}
            maxZoom={9}
            zoomControl={false}
            attributionControl={false}
          >
            <TileLayer
              // attribution='Tiles &copy: <a href="https://www.maptilesapi.com/">MapTiles API</a>, Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={`https://maptiles.p.rapidapi.com/en/map/v1/{z}/{x}/{y}.png?rapidapi-key=${process.env.REACT_APP_RAPID_API_KEY}`}
            />
            {byUR === "utility" ? polylines : null}

            <ZoomControl position="bottomleft" />
            {data.length && UTILITY_DICS && (
              <GeoJSON
                key={`GeoJSON - ${yearMonth} - ${pq_type} - ${byUR} - ${byMY}`}
                ref={geoRef}
                data={geoJSONData}
                style={style}
                onEachFeature={handleEachFeature}
              />
            )}
          </MapContainer>
        </div>
      </div>
    </section>
  );
};

export default DataMap;
