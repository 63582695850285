import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/app.css";


if (typeof process.env.REACT_APP_GTM_ID !== 'undefined') {
	const gtmParams = {
		gtmId: process.env.REACT_APP_GTM_ID
	};

	if (typeof process.env.REACT_APP_GTM_PREVIEW_ENV !== 'undefined') {
		gtmParams.preview = process.env.REACT_APP_GTM_PREVIEW_ENV;
	}

	TagManager.initialize(gtmParams);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
		<Route path="/thd" element={<App stat="thd" />} />
        <Route path="/cpqi" element={<App stat="cpqi" />} />
        <Route path="/" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
