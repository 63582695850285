import { Button } from "react-bootstrap"
import { useCurrentStat } from "./StatContext";
import { HomeIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const SelectDatasetButton = () => {
	const { toggleStatModal } = useCurrentStat();
	return (
		<Button as={Link} className="home-button" onClick={toggleStatModal} to="/"><HomeIcon className="icon" /></Button>
	)
};

export default SelectDatasetButton;