import { useCallback, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useCurrentStat } from "../StatContext";
import { getYearMonthFromDate } from "../../helpers";

const HeaderBottom = ({
	setYearMonth
}) => {
	const { currentStat } = useCurrentStat();

	const [params, setParams] = useSearchParams();
	const category = useMemo(() => params.get("category") || "cpqi", [params.get("category")]);
	const byUR = useMemo(() => params.get("byUR") || "utility", [params.get("byUR")]);
	const byMY = useMemo(() => params.get("byMY") || "month", [params.get("byMY")]);
	const month = useMemo(() => params.get("month") || null, [params.get("month")]);

	const handleClick = useCallback((e) => {
		e.preventDefault();
		const newParams = {
			category: category || "cpqi",
			byUR: byUR || "utility",
			byMY: byMY || "month",
			...e.target.dataset
		};

		if (month) newParams.month = month;

		setParams(newParams);
	}, [byUR, byMY, category, month]);

	return (
		<div className="header-bottom">
		{
			currentStat === "cpqi"
				? (
					<nav className={`primary-nav`}>
						<ul>
						<li>
							<Link
							onClick={handleClick}
							className={
								category === "po_and_sag_and_swell_warning_events"
								? "active"
								: ""
							}
							data-category="po_and_sag_and_swell_warning_events"
							>
							All Events
							</Link>
						</li>
						<li>
							<Link
							onClick={handleClick}
							className={category === "cpqi" ? "active" : ""}
							data-category="cpqi"
							>
							CPQI
							</Link>
						</li>
						<li>
							<Link
							onClick={handleClick}
							className={category === "po_events" ? "active" : ""}
							data-category="po_events"
							>
							Power Outages
							</Link>
						</li>
						<li>
							<Link
							onClick={handleClick}
							className={
								category === "sag_warning_events" ? "active" : ""
							}
							data-category="sag_warning_events"
							>
							Brownouts
							</Link>
						</li>
						<li>
							<Link
							onClick={handleClick}
							className={
								category === "swell_warning_events" ? "active" : ""
							}
							data-category="swell_warning_events"
							>
							Surges
							</Link>
						</li>
						</ul>
					</nav>
				) : null
		}

        {/*<div className={`type-toggle-navigations`}>*/}
        {
			currentStat === "cpqi"
				? (  
				<nav className={`type-toggle-nav utility-region`}>
					<ul>
					<li>
						<Link
						onClick={(e) => {
							e.preventDefault();
							setParams({ category, byUR: "utility", byMY, month });
						}}
						className={byUR !== "region" ? "active" : ""}
						>
						By Utility
						</Link>
					</li>
					<li>
						<Link
						onClick={(e) => {
							e.preventDefault();
							setParams({ category, byUR: "region", byMY, month });
						}}
						className={byUR === "region" ? "active" : ""}
						>
						By Region
						</Link>
					</li>
					</ul>
				</nav>
				) : <div className="fs-20 h-100 vertical-middle showing-by-utility align-self-end">Showing Data by Utility</div>}

          <nav className="type-toggle-nav month-average">
            <ul>
              <li>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
					const newParams = { byUR, byMY: "month", month };
					if (month) newParams.month = month;
					if (currentStat === "cpqi") newParams.category = category;
                    setParams(newParams);
                  }}
                  className={byMY !== "year" ? "active" : ""}
                >
                  By MONTH
                </Link>
              </li>
              <li>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
					const _month = getYearMonthFromDate();
					const newParams = { byUR, byMY: "year", month: _month };
					if (currentStat === "cpqi") newParams.category = category;
					// if (month) newParams.month = month;
					setParams(newParams);
					setYearMonth(_month);
                  }}
                  className={byMY === "year" ? "active" : ""}
                >
                  AVERAGE LAST 12 MONTHS
                </Link>
              </li>
            </ul>
          </nav>
        {/*</div>*/}
      </div>
	);
};

export default HeaderBottom;
