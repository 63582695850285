import React, { lazy, useCallback, useState } from "react";
import { BoltIcon } from "@heroicons/react/24/outline";

import logo from "./../../assets/images/logo_ting_white.svg";
import InfoModal  from "../InfoModal/InfoModal";
import ShareButtons from "./ShareButtons";
import HeaderBottom from "./HeaderBottom";

import content from "../../assets/json/content.json";
import SelectDatasetButton from "../SelectDatasetButton";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";


const { links } = content;

const THDHeader = ({ setYearMonth }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const handleModalToggle = () => {
	setToggleModal((prev) => {
		if (!prev) {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'event',
					eventProps: {
						category: 'info_modal',
						action: 'open',
						label: "Opened info panel."
					}
				});
			}
		}

		return !prev;
	});
  };

  const modalOutput = {
    type: `info`,
    title: `About Total Harmonic Distortion (THD)`,
    content: `<p>THD is a key power quality metric that measures electrical "noise" or "distortion." The industry standard specifies that THD levels remain below 8%. Levels above 8% reduce energy efficiency, damage appliances and devices in homes, and harm power equipment on the grid, driving up energy costs for all homes in the utility region. THD is measured by Ting, a plug-in DIY sensor that not only detects electrical fire hazards, but also monitors power quality and grid resiliency nationwide through its network of sensors.</p>
            <p>This page shows the percentage of homes in a utility service area experiencing THD levels over the industry accepted limit of 8%. Monitoring THD can help utilities, government agencies, and researchers improve grid resiliency and power quality nationwide.</p>
			<p>For more information about THD and how Ting helps protect your home, visit <a href="https://www.tingfire.com/help/power-quality/thd/" target="_blank" title="TingFire">tingfire.com</a>.</p>
            `,
  };

  return (
    <React.Fragment>
		<Helmet>
			<title>Total Harmonic Distortion - TingFire</title>
			<meta property="og:title" content="THD - Total Harmonic Distortion - TingFire" />
			<meta property="og:description" content="The first resource of its kind to report an objective and accurate view of consumer power quality across the U.S. electrical grid each month." />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={`https://${document.location.host}/ting-thd-og.jpg`} />
			<meta property="og:url" content={document.location.href} />
			<meta name="twitter:image:alt" content="THD - Total Harmonic Distortion - TingFire"></meta>
		</Helmet>
      <div className="header-top d-flex align-items-center justify-content-between">
        <div className="header-logo d-flex align-items-center">
			<SelectDatasetButton />
		  	<img src={logo} alt={`Ting Logo`} />
			<div className="title-container">
				<h1 className="me-4">Total Harmonic Distortion (THD)</h1>
				<p>Measures the distortion in power delivered to homes, which reduces energy efficiency and damages appliances</p>
			</div>
        </div>
		<div className="header-top-right d-flex align-items-center align-self-start">
			<Button
				className={`btn-about-cpqi-score`}
				onClick={() => handleModalToggle()}
				title={`Click to Read About the CPQI Score`}
				variant="link"
			>
				<BoltIcon /> About THD
			</Button>

			<ShareButtons />
		</div>

      </div>

	  <HeaderBottom setYearMonth={setYearMonth} />

      <InfoModal
        modalShow={toggleModal}
        setModalShow={handleModalToggle}
        modalContent={modalOutput}
      />
    </React.Fragment>
  );
};

export default THDHeader;
