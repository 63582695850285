import React, { lazy, useCallback, useState } from "react";
import { BoltIcon } from "@heroicons/react/24/outline";

import logo from "./../../assets/images/logo_ting.svg";
import InfoModal from "../InfoModal/InfoModal";
import ShareButtons from "./ShareButtons";
import HeaderBottom from "./HeaderBottom";

import content from "../../assets/json/content.json";
import SelectDatasetButton from "../SelectDatasetButton";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useCurrentStat } from "../StatContext";

// const InfoModal = lazy(() => import("../InfoModal/InfoModal"));

const { links } = content;

const Header = ({ setYearMonth }) => {
	const { currentStat } = useCurrentStat();
  const [toggleModal, setToggleModal] = useState(false);
  const handleModalToggle = () => {
	setToggleModal((prev) => {
		if (!prev) {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'event',
					eventProps: {
						category: 'info_modal',
						action: 'open',
						label: "Opened info panel."
					}
				});
			}
		}

		return !prev;
	});
  };

  const modalOutput = {
    type: `info`,
    title: `About the CPQI Score`,
    content: `<p>While on the job helping to prevent home electrical fires, Ting also alerts homeowners on community power problems in real-time: power outages/interruptions, power brownouts, and power surges affecting homes in their area. The CPQI™ is a monthly accumulation of these events mapped to electric utility provider footprints.</p>
            
            <h3>Notes:</h3>
            
            <ul class="about-info-list mb-4">
                <li class='about-info-item location-marker'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                    </svg>
                    
                    Moving from right to left in the table below, the Surge, Brownout, and Power Outage Index values represent the frequency of each event that occurred in the report month per Ting-protected home.
                </li>
                <li class='about-info-item chip'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
                    </svg>

                    The All Events Index value is the sum of these values. For instance, an All Events Index value of 1.25 means that each home, on average, experienced slightly more than one event in the month - whether a surge, brownout, or power outage.
                </li>
                <li class='about-info-item clipboard-list'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                    </svg>
                
                    In contrast, the CPQI is a weighted value that considers all three of these events and their relative potential impact on the home.
                </li>
            </ul>

			<p>For more information, read the <a href="${links.faq}" target="_blank" title="Frequently Asked Questions">Frequently Asked Questions</a>.</p>
            `,
  };

  return (
    <React.Fragment>
		{
			currentStat === "cpqi"
				? (
					<Helmet>
						<title>Consumer Power Quality Index - TingFire</title>
					</Helmet>
				)
				: (
					<Helmet>
						<title>Grid Resiliency Data by Whisker Labs Ting Sensor Network</title>
						<meta name="description" content="Measuring and tracking grid resilience with the most comprehensive sensor network to ever monitor the grid." />
					</Helmet>
				)
		}
      <div className="header-top d-flex align-items-center justify-content-between">
        <div className="header-logo d-flex align-items-center">
			<SelectDatasetButton />
			<img alt={`Ting Logo`} src={logo} />
			<div className="title-container">
				<h1>Consumer Power Quality Index</h1>
				<p>Measures grid faults that cause damage and community power outages</p>
			</div>
		  
        </div>
		<div className="header-top-right d-flex align-items-center align-self-start">
			<Button
				className={`btn-about-cpqi-score`}
				onClick={() => handleModalToggle()}
				title={`Click to Read About the CPQI Score`}
				variant="link"
			>
				<BoltIcon /> About the CPQI
			</Button>

			<ShareButtons />
		</div>
      </div>

	  <HeaderBottom setYearMonth={setYearMonth} />

      <InfoModal
        modalShow={toggleModal}
        setModalShow={handleModalToggle}
        modalContent={modalOutput}
      />
    </React.Fragment>
  );
};

export default Header;
