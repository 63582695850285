import {
  CPQI_GRADES,
  PO_GRADES,
  PO_SAG_SWELL_GRADES,
  SAG_GRADES,
  SWELL_GRADES,
  THD_GRADES
} from "./constants";

export const getGradeScale = (pq) => {
  let grades = CPQI_GRADES;
  if (pq == "sag_warning_events" || pq == "sag_watch_events") {
    grades = SAG_GRADES;
  } else if (pq == "swell_warning_events" || pq == "swell_watch_events") {
    grades = SWELL_GRADES;
  } else if (pq == "po_and_sag_and_swell_warning_events") {
    grades = PO_SAG_SWELL_GRADES;
  } else if (pq == "cpqi") {
	grades = CPQI_GRADES;
  } else if (pq == "thd_8per" ) {
	grades = THD_GRADES;
  }
  return grades;
};

export const getColorMap = (d, grade_values, offset = 0) => {
  return d >= grade_values[12 + offset]
	? "#FF0000"
  	: d >= grade_values[11 + offset]
	? "#FF0000"
  	: d >= grade_values[10 + offset]
    ? "#FF4F28"
    : d >= grade_values[9 + offset]
    ? "#FF964E"
    : d >= grade_values[8 + offset]
    ? "#E6CE73"
    : d >= grade_values[7 + offset]
    ? "#B3F396"
    : d >= grade_values[6 + offset]
    ? "#80FFB4"
    : d >= grade_values[5 + offset]
    ? "#4CF3CE"
    : d >= grade_values[4 + offset]
    ? "#17CEE3"
    : d >= grade_values[3 + offset]
    ? "#1A96F3"
    : d >= grade_values[2 + offset]
    ? "#4D4FFC"
    : d >= grade_values[1 + offset]
    ? "#0000FF"
    : d >= grade_values[0 + offset]
    ? "#0000FF"
    : "#0000FF";
};

const yearMonthReg = /\d{4}_\d{2}/g;
export const validateYearMonth = (yearMonth) => typeof yearMonth === 'string' && yearMonth.match(yearMonthReg);

export const getYearMonthFromDate = (date = null) => {
	if (!date) {
	  date = new Date();
	  date.setDate(1);
	  date.setMonth(date.getMonth() - 1);
	}
  
	return `${date.getFullYear()}_${
	  date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
	}`;
};